import { createPartNodeHeadersBootstrap } from 'server/functions/createPartNodeHeadersBootstrap'
import { definitions } from 'api/generated/masked_ads'
import { fetchApi } from 'api/fetchApi'

export const fetchAdsDictionaryServerApi = async (ip: string) => {
  const headers = createPartNodeHeadersBootstrap(ip)

  return await fetchApi<definitions['DictionariesResponse']>(
    '/ad/dictionaries',
    {
      headers,
    }
  )
}

export const fetchAdsDictionaryApi = () => {
  return fetchApi<definitions['DictionariesResponse']>('/ad/dictionaries')
}
