import { Api6Error } from 'api/api.types'
import { ApiData } from 'api/fetchApi'
import {
  AppDispatch,
  AsyncAction,
  FulfilledAsyncAction3,
} from 'actions/actions.types'
import { CATCH_NETWORK_ERROR } from 'api/api.constants'
import { Middleware, MiddlewareAPI } from 'redux'
import { Paths } from 'components/components.paths'
import { RootState } from 'reducers/list'
import { VERIFY_CAPTCHA } from 'actions/recaptcha/recaptchaAction'
import {
  isActionCaptchaNeeded,
  isAuthorizationAnonymousError,
  isAuthorizationError,
} from 'api/api.functions'
import { isActionWithoutErrors } from 'actions/actions.functions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'actions/system/router'
import { tryUpdateAnonymousAuthorizationAction } from 'actions/authorization/authorizationAnonymousAction'
import { tryUpdateAuthorizationAction } from 'actions/authorization/authorizationAction'

const ERROR_LIMIT = 50
let errorCount = 0
let captchaBlocked: AsyncAction<unknown>[] = []

// const dispatchPostponed = (store: AppStore) => {
//   captchaBlocked.forEach(action => store.dispatch(action))
//   captchaBlocked = []
// }

const runPostponed = (
  store: MiddlewareAPI<AppDispatch, RootState>,
  postponed: AsyncAction<unknown>[]
) => {
  postponed.forEach((action) => {
    delete action.error

    return store.dispatch(action)
  })
}

export const readyStatePromise: Middleware<unknown, RootState, AppDispatch> =
  (store) => (next) => (action: AsyncAction<unknown>) => {
    // if (action.type === UPDATE_CAPTCHA_KEY ) {
    //   dispatchPostponed(store)
    // }

    if (!action.promise) {
      return next(action)
    }

    const makeAction = (ready: boolean, data?: ApiData<unknown>) => {
      const newAction: AsyncAction<unknown> = {
        ...action,
        ...{ ready },
        ...data,
      }

      /** Обработка отложенных из-за ошибок действий */
      if (process.env.browser) {
        if (isActionCaptchaNeeded(newAction)) {
          captchaBlocked.push({
            ...newAction,
            ready: false,
          })
        } else if (isAuthorizationAnonymousError(newAction)) {
          /** Если пришла ошибка по авторизации, пробуем еще раз перед экшеном */
          store
            .dispatch(
              tryUpdateAnonymousAuthorizationAction({
                ...newAction,
                ready: false,
                error: undefined,
              })
            )
            .catch(console.error)
        } else if (isAuthorizationError(newAction)) {
          store
            .dispatch(
              tryUpdateAuthorizationAction({
                ...newAction,
                ready: false,
                error: undefined,
              })
            )
            .catch(console.error)
        }
      }
      delete (newAction as unknown as FulfilledAsyncAction3).promise

      if (ready && isActionWithoutErrors(newAction)) {
        if (action.type === VERIFY_CAPTCHA) {
          runPostponed(store, captchaBlocked)
          captchaBlocked = []
        }
      }

      return newAction
    }

    next(makeAction(false))

    return action.promise().then(
      (response) => {
        if (response.ok) {
          return next(
            makeAction(true, {
              result: response.result,
              status: response.status,
            })
          )
        }

        return next(
          makeAction(true, {
            error: response.result as Api6Error,
            status: response.status,
          })
        )
      },
      (error: Error) => {
        if (
          process.env.browser &&
          !(errorCount % ERROR_LIMIT) &&
          window.onerror
        ) {
          window.onerror(
            error.message || 'Common error',
            'Promise middleware',
            -1,
            -1,
            error
          )
        }
        errorCount += 1
        console.error('Promise middleware', error)
        if (error.name === CATCH_NETWORK_ERROR) {
          const networkErrorFullPath = mergeAllUrls(Paths.networkError)
          if (
            store.getState().router?.location?.pathname !==
              networkErrorFullPath &&
            action.critical === true
          ) {
            store.dispatch(push(networkErrorFullPath))
          }
        }

        return next(
          makeAction(true, {
            error: {
              code: CATCH_NETWORK_ERROR,
              message: 'Client network error',
              data: null,
              humanMessage: null,
            },
          })
        )
      }
    )
  }
