import { FC, useEffect } from 'react'
import {
  authorizationAction,
  getUserDataAction,
} from 'actions/authorization/authorizationAnonymousAction'
import { useAppDispatch } from 'hooks/useAppDispatch'

export const Authorization: FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const authorize = async () => {
      await dispatch(authorizationAction())
      dispatch(getUserDataAction())
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authorize().catch(console.error)
  }, [dispatch])

  return null
}
