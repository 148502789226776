import {
  AppDispatch,
  AsyncAction,
  AsyncThunkAction,
} from 'actions/actions.types'
import { AuthorizationService } from 'components/page/authorization/authorization.constants'
import {
  CONFIRM_ANNOUNCE_DELETE,
  SEND_CREATE_ANNOUNCE,
  SUBMIT_ANSWER,
} from 'actions/captchaInlineActions'
import { FETCH_CURRENT_PAYMENT } from 'actions/billing/fetchCurrentOrderAction'
import { Paths } from 'components/components.paths'
import { announceIdQueryParam } from 'hooks/useQueryStringParams'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'actions/system/router'
import { refreshTokenAction } from './refreshTokenAction'

/**
 * ID объявления может быть не только в стейте, но и в query урла,
 * если перешли из почты.
 */
const findAnnounceId = (stateAnnounceId: number | null): number | undefined => {
  if (stateAnnounceId) {
    return stateAnnounceId
  }

  const urlSearchParams = new URLSearchParams(location.search)

  return Number(urlSearchParams.get(announceIdQueryParam))
}

/**
 * Проверить токен, если есть попробовать обновить.
 * Если токен нет то отправляем на авторизацию.
 */
export const tryUpdateAuthorizationAction =
  (action: AsyncAction<unknown>): AsyncThunkAction<Promise<unknown>> =>
  async (dispatch, getState) => {
    const appDispatch = dispatch as AppDispatch

    /**
     * announceId нужен в случае ошибки, при ответе на объявление
     * Чтобы передать дальше на полный flow авторизации,
     * выполнить отправку ответа, используя announceId
     */
    const announceId = findAnnounceId(getState().announceAnswerForm?.announceId)
    const refreshToken = getState().authorization.accessToken

    if (refreshToken) {
      const result = await appDispatch(refreshTokenAction())

      if (!result.error) {
        return dispatch(action)
      }
    }

    const service = `${createServiceUrl(action.type, announceId)}`

    return dispatch(push(mergeAllUrls(Paths.authorizeWithEmailPart, service)))
  }

const createServiceUrl = (
  type: string,
  announceId: number | null | undefined
) => {
  switch (type) {
    case FETCH_CURRENT_PAYMENT:
      return AuthorizationService.paymentExternalService

    case SEND_CREATE_ANNOUNCE:
      return AuthorizationService.createAnnounce

    case CONFIRM_ANNOUNCE_DELETE:
      if (announceId) {
        return mergeAllUrls(
          AuthorizationService.deleteAnnounce,
          String(announceId)
        )
      }
      break

    case SUBMIT_ANSWER:
      if (announceId) {
        return mergeAllUrls(
          AuthorizationService.answersAnnounce,
          String(announceId)
        )
      }

      break
  }

  return AuthorizationService.common
}
