import thunk from 'redux-thunk/es/index'
import {
  AnyAction,
  Dispatch,
  Middleware,
  Reducer,
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from 'redux'
import { AppStore } from './store.types'
import { RootReducers, RootState, staticReducers } from 'reducers/list'
import { RouterState } from 'redux-first-history'

import { readyStatePromise } from './readyStatePromise'

export const combineStaticReducers = ({
  routerReducer,
  asyncReducers = {},
}: {
  routerReducer?: unknown // TODO: проработать типы.
  asyncReducers?: Partial<RootReducers>
}): Reducer => {
  const reducers = {
    router: routerReducer as Reducer,
    ...staticReducers,
    ...asyncReducers,
  }

  return combineReducers(reducers)
}

const createMiddleware = (
  connectTools: boolean,
  routerMiddleware: Middleware<object, any, Dispatch<AnyAction>>
) => {
  const middleware = [
    routerMiddleware,
    thunk,
    readyStatePromise,
    // loggerMiddleware,
  ]

  if (process.env.production) {
    return applyMiddleware(...middleware)
  } else if (process.env.browser) {
    const composeEnhancers =
      (connectTools &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
          shouldHotReload: false, // https://github.com/reduxjs/redux-devtools/issues/304
        } as any)) ||
      compose

    return composeEnhancers(applyMiddleware(...middleware))
  } else {
    return applyMiddleware(...middleware)
  }
}

export const configureStore = (
  initialState: RootState,
  connectTools = true,
  routerMiddleware: Middleware<object, any, Dispatch<AnyAction>>,
  routerReducer: Reducer<RouterState>
): AppStore => {
  const middleware = createMiddleware(connectTools, routerMiddleware)

  return createStore(
    combineStaticReducers({ routerReducer }),
    initialState,
    middleware
  )
}
