import {
  AnnounceSettingsFormTypes,
  FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS,
  FETCH_AD_SETTINGS_FORM,
  MARKED_INVALID_PARAMS,
  RESET_INVALID_PARAMS,
  RESET_SETTINGS_FORM,
  SAVE_ANNOUNCE_FORM,
  UPDATE_ANNOUNCE_FORM,
  UPDATE_FORM_WITHOUT_LOCATION,
  UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS,
  UPDATE_FULL_FORM_FROM_SERVER,
  UPDATE_TEMPORARY_FORM,
} from 'actions/announce/announceSettingsFormAction'
import { FETCH_EXTENDED_LOCATION, LocationTypes } from 'actions/locationAction'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { Reducer } from 'redux'
import { defaultPromiseReducer } from 'reducers/system/defaultPromiseReducer'
import { definitions } from 'api/generated/masked_ads'
import { initialStateReducer } from 'reducers/system/initialStateReducer'

export interface AnnounceSettingsFormState {
  defaultForm: definitions['AdSearchPreferencesResponse']
  changingForm: definitions['AdSearchPreferencesResponse']
  formLoaded: boolean
  isNotValid: boolean
  locationExtendedLoading: boolean
}

const defaultFormFieldValue: definitions['AdSearchPreferencesResponse'] = {
  whoIsSeeker: 0,
  whoLookingFor: 0,
  minAge: 0,
  maxAge: 0,
  tags: [],
  location: {
    country: 0,
    region: 0,
    city: 0,
    metro: 0,
    location: '',
    countryName: '',
    regionName: '',
    cityName: '',
    metroName: '',
  },
}

export const announceSettingsFormReducer: Reducer<
  AnnounceSettingsFormState,
  AnnounceSettingsFormTypes | RestoreInitialStateAction | LocationTypes
> = (
  state = {
    defaultForm: { ...defaultFormFieldValue },
    changingForm: { ...defaultFormFieldValue },
    formLoaded: false,
    isNotValid: false,
    locationExtendedLoading: false,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_ANNOUNCE_FORM:
      return {
        ...state,
        changingForm: {
          ...state.changingForm,
          [action.name]: action.value,
        },
      }

    /** Решение для перехода */
    case UPDATE_TEMPORARY_FORM: {
      return {
        ...state,
        defaultForm: {
          ...action.form,
          tags: state.defaultForm.tags,
          location: {
            ...state.defaultForm.location,
            location: action.form.location,
          },
        },
        changingForm: {
          ...action.form,
          tags: state.defaultForm.tags,
          location: {
            ...state.defaultForm.location,
            location: action.form.location,
          },
        },
        formLoaded: true,
      }
    }

    case FETCH_ADDITIONAL_ANNOUNCE_FORM_TAGS:
      return defaultPromiseReducer<typeof state, typeof action.result>(
        state,
        action,
        () => ({
          locationExtendedLoading: true,
        }),
        (result) => {
          if (result) {
            return {
              ...state,
              changingForm: {
                ...state.changingForm,
                tags: result.tags,
              },
              defaultForm: {
                ...state.defaultForm,
                tags: result.tags,
              },
            }
          }

          return state
        }
      )

    case UPDATE_FORM_WITHOUT_LOCATION:
      return {
        ...state,
        defaultForm: {
          ...action.form,
          location: {
            ...state.defaultForm.location,
            location: action.form.location,
          },
        },
        changingForm: {
          ...action.form,
          location: {
            ...state.defaultForm.location,
            location: action.form.location,
          },
        },
        formLoaded: true,
      }

    case UPDATE_FULL_FORM_FROM_SERVER: {
      return {
        ...state,
        defaultForm: action.form,
        changingForm: action.form,
        formLoaded: true,
      }
    }

    case SAVE_ANNOUNCE_FORM:
      return defaultPromiseReducer<typeof state, typeof action.result>(
        state,
        action,
        undefined,
        () => {
          return {
            ...state,
            defaultForm: {
              ...state.changingForm,
            },
            isNotValid: false,
          }
        }
      )

    case MARKED_INVALID_PARAMS:
      return {
        ...state,
        isNotValid: true,
      }

    case RESET_INVALID_PARAMS:
      return {
        ...state,
        isNotValid: false,
      }

    case RESET_SETTINGS_FORM:
      return {
        ...state,
        changingForm: {
          ...state.defaultForm,
        },
      }

    case UPDATE_FORM_WITHOUT_REQUEST_WITHOUT_TAGS:
      return {
        ...state,
        defaultForm: {
          ...state.defaultForm,
          ...action.form,
        },
        changingForm: {
          ...state.changingForm,
          ...action.form,
        },

        formLoaded: true,
      }

    case FETCH_EXTENDED_LOCATION:
      return defaultPromiseReducer<typeof state, typeof action.result>(
        state,
        action,
        () => ({
          locationExtendedLoading: true,
        }),
        (result) => {
          if (result && result[action.locationId]) {
            const location = result[
              action.locationId
            ] as unknown as definitions['NamedLocation']

            return {
              ...state,
              changingForm: {
                ...state.changingForm,
                location,
              },
              defaultForm: {
                ...state.defaultForm,
                location,
              },
              locationExtendedLoading: false,
            }
          }

          return state
        }
      )

    case FETCH_AD_SETTINGS_FORM:
      return defaultPromiseReducer<typeof state, typeof action.result>(
        state,
        action,
        undefined,
        (result) => {
          if (result) {
            return {
              defaultForm: { ...result },
              changingForm: { ...result },
              formLoaded: true,
            }
          }

          return state
        }
      )

    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'announceForm')

    default:
      return state
  }
}
