import {
  AnnounceFindInParams,
  AnnounceFindOutParams,
} from 'reducers/announce/announce.types'
import { DatingGoals } from 'reducers/announce/announceDatingGoalsReducer'
import { GenderLetters } from 'constants/announces/gender'
import {
  announceAdsListPath,
  announceListPath,
} from 'components/components.paths'
import { convertLocation } from 'functions/convertLocation'
import { definitions } from 'api/generated/masked_ads'
import { limit } from 'api/announce/searchAnnounceListApi'
import { matchPath } from 'react-router'
import { maximumAge, minimumAge } from 'constants/age'

export const firstPageListAnnounces = 1

export enum PreviousOrNext {
  PreviousAnnounce = 'previousAnnounce',
  NextAnnounce = 'nextAnnounce',
}

export const findParameters = ({
  path,
  languagesLocalesString,
}: {
  path?: string
  languagesLocalesString: string
}): AnnounceFindOutParams | false => {
  if (!path) {
    return false
  }

  const pathWithLocale = `${languagesLocalesString}${announceAdsListPath}`

  const match = matchPath(path, {
    path: pathWithLocale,
    exact: true,
    strict: false,
  })

  if (match) {
    const params = match.params as AnnounceFindInParams
    const tagsOrGoal = params.tags?.split(',').map((id) => Number(id))

    if (params) {
      const mainParams = {
        whoIsSeeker: GenderLetters[params.whoIsSeeker],
        whoLookingFor: GenderLetters[params.whoLookingFor],
        minAge: Number(params.minAge),
        maxAge: Number(params.maxAge),
        location: params.location,
        tags: tagsOrGoal,
      }

      if (params.page) {
        return {
          ...mainParams,
          offset: limit * (Number(params.page) - firstPageListAnnounces),
          page: Number(params.page),
        }
      } else {
        return {
          ...mainParams,
          offset: 0,
          page: 0,
        }
      }
    }
  }

  return false
}

export const findGoalParameter = (
  path: string,
  languagesLocalesString: string
) => {
  const pathWithLocale = `${languagesLocalesString}${announceListPath}`

  const match = matchPath(path, {
    path: pathWithLocale,
    exact: true,
    strict: false,
  })

  if (match) {
    const params = match.params as AnnounceFindInParams

    if (params) {
      const mainParams = {
        whoIsSeeker: GenderLetters[params.whoIsSeeker],
        whoLookingFor: GenderLetters[params.whoLookingFor],
        minAge: Number(params.minAge),
        maxAge: Number(params.maxAge),

        location: params.location,
      }

      if (params.page) {
        return {
          ...mainParams,
          offset: limit * (Number(params.page) - firstPageListAnnounces),
          page: Number(params.page),
        }
      } else {
        return {
          ...mainParams,
          offset: 0,
          page: 0,
        }
      }
    }
  }

  return false
}

const isValidLocation = (location: string) => {
  const _location = location.split('-')

  /**
   * Не должно быть
   *
   * 0-1-1-1
   * 1-0-1-1
   * 1-1-0-1
   */

  if (
    Number(_location[0]) === 0 ||
    (Number(_location[0]) !== 0 &&
      Number(_location[1]) === 0 &&
      Number(_location[2]) !== 0) ||
    (Number(_location[0]) !== 0 &&
      Number(_location[1]) !== 0 &&
      Number(_location[2]) === 0 &&
      Number(_location[3]) !== 0)
  ) {
    return false
  }

  return true
}

export const numberDatingGoal = (datingGoals: DatingGoals[]): number[] => {
  return [
    ...new Set(
      datingGoals
        .reduce<{ value: number; name: string }[]>(
          (acc, { targets }) => [...targets, ...acc],
          []
        )
        .reduce<number[]>((acc, item) => [item.value, ...acc], [])
    ),
  ]
}

export const convertToObject = (
  datingGoals: DatingGoals[]
): Record<string, string> => {
  return datingGoals
    .reduce<{ value: number; name: string }[]>(
      (acc, { targets }) => [...targets, ...acc],
      []
    )
    .reduce<Record<number, string>>((acc, item) => {
      const { value, name } = item

      if (!acc[item.value]) {
        return {
          ...acc,
          [value]: name,
        }
      }

      return { ...acc }
    }, {})
}

/**
 *
 * @param params
 * @param datingGoals
 * new Set позволяет избавится от дублей.
 */
export const isValidParams = (
  params: AnnounceFindOutParams,
  tags: definitions['AdTagResponse'][]
): boolean => {
  if (
    params.minAge < minimumAge ||
    params.minAge > maximumAge ||
    params.maxAge < minimumAge ||
    params.maxAge > maximumAge ||
    params.maxAge < params.minAge
  ) {
    return false
  }

  /**
   * мы проверяем на длину
   * если длина 1 то это цель и мы должны пропустить на дальнейшую проверку
   * Далее мы просто сделаем редирект на нужный url заменив цель на теги, взятые из формы по умолчанию
   * полученной по api
   */
  if (params.tags.length > 1) {
    const tagsIds = tags.map((tag) => tag.id)
    const tagsParamIds = params.tags.map((id) => Number(id))

    if (!includesAll(tagsIds, tagsParamIds)) {
      return false
    }
  }

  if (!isValidLocation(params.location)) {
    return false
  }

  return true
}

export const isNotEqual = (
  parameters: AnnounceFindOutParams,
  form: definitions['AdSearchPreferencesResponse']
): boolean => {
  const location = convertLocation(parameters.location)

  const _tagsIds = form.tags.map(({ id }) => {
    return id
  })

  if (
    location === form.location.location &&
    parameters.whoIsSeeker === form.whoIsSeeker &&
    parameters.whoLookingFor === form.whoLookingFor &&
    parameters.minAge === form.minAge &&
    parameters.maxAge === form.maxAge &&
    includesAll(parameters.tags, _tagsIds)
  ) {
    return false
  }

  return true
}

const includesAll = (baseArray: number[], searchArray: number[]) =>
  searchArray.every((id) => baseArray.includes(id))
