import { definitions } from 'api/generated/masked_ads'
import { fetchAdsDictionaryApi } from 'api/dictionary/fetchAdsDictionaryApi'

export const ADD_ADS_DICTIONARY = 'ADD_ADS_DICTIONARY' as const

export const addAdsDictionaryAction = (
  dictionary: definitions['DictionariesResponse'],
  locale: string
) => ({
  type: ADD_ADS_DICTIONARY,
  dictionary,
  locale,
})

export const FETCH_ADS_DICTIONARY = 'FETCH_ADS_DICTIONARY' as const

export const fetchAdsDictionaryAction = (locale: string) => ({
  type: FETCH_ADS_DICTIONARY,
  promise: () => fetchAdsDictionaryApi(),
  locale,
})
