import { useLocation } from 'react-router'
import { useMemo } from 'react'

export const announceIdQueryParam = 'id'

export const useQueryStringParams = (): {
  announceId: string
  /**
   * Токен перестает использоваться в новом АПИ:
   * https://youtrack.mamba.ru/issue/MSKD-947
   */
  token: string
  mailstat: string
} => {
  const { search } = useLocation()

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search)

    const announceId = urlSearchParams.get(announceIdQueryParam) as string
    const token = urlSearchParams.get('token') as string
    const mailstat = urlSearchParams.get('mailstat') as string

    return {
      announceId,
      token,
      mailstat,
    }
  }, [search])
}
