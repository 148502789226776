export enum ErrorCode {
  blockedTracker = 'blocked_tracker',
  limitReached = 'ad_limit_reached',
  invalidInput = 'invalid_input',
  authRequired = 'auth_required',
  emailInBlackList = 'email_in_black_list',
  emailInStopList = 'email_in_stop_list',
  adLimitUserVerificationRequired = 'ad_limit_user_verification_required',
  adLimitUserPremiumRequired = 'ad_limit_user_premium_required',
}
