import {
  AnnounceAnswerFormState,
  announceAnswerFormReducer,
} from 'reducers/announce/announceAnswerFormReducer'
import {
  AnnounceConfirmState,
  announceConfirmReducer,
} from 'reducers/announce/announceConfirmReducer'
import {
  AnnounceCountState,
  announceCountReducer,
} from 'reducers/announce/announceCountReducer'
import {
  AnnounceCreateState,
  announceCreateReducer,
} from 'reducers/announce/announceCreateReducer'
import {
  AnnounceDatingGoalsState,
  announceDatingGoalsReducer,
} from 'reducers/announce/announceDatingGoalsReducer'
import {
  AnnounceDeleteState,
  announceDeleteReducer,
} from 'reducers/announce/announceDeleteReducer'
import {
  AnnounceListState,
  announceListReducer,
} from 'reducers/announce/announceListReducer'
import {
  AnnounceSettingsFormState,
  announceSettingsFormReducer,
} from 'reducers/announce/announceSettingsFormReducer'
import {
  AnnounceShowcaseState,
  announceShowcaseReducer,
} from 'reducers/announce/announceShowcaseReducer'
import {
  AnnounceState,
  announceReducer,
} from 'reducers/announce/announceReducer'
import {
  AnnounceSubscribeManagementState,
  announceSubscribeManagementReducer,
} from 'reducers/announce/announceSubscribeManagementReducer'
import {
  AuthorizationState,
  authorizationReducer,
} from 'reducers/authorizationReducer'
import {
  AutoCompleteLocationState,
  autoCompleteLocationReducer,
} from 'reducers/autoCompleteLocation/announceAutoCompleteLocationReducer'
import {
  DictionaryState,
  dictionaryReducer,
} from './dictionary/dictionaryReducer'
import { ErrorState, errorReducer } from 'reducers/errorReducer'
import { MetaDataState, metaDataReducer } from 'reducers/metaDataReducer'
import { RecaptchaState, recaptchaReducer } from 'reducers/recaptcha'
import { Reducer } from 'redux'
import { RouterState } from 'redux-first-history'
import { SeoLinksState, seoLinksReducer } from 'reducers/seoLink'
import {
  SubscriptionsState,
  subscriptionsReducer,
} from 'reducers/subscriptions/subscriptionsReducer'
import { SystemState, systemReducer } from 'reducers/system/systemReducer'
import { UserState, userReducer } from './user/userReducer'
import { VerifyState, verifyReducer } from 'reducers/verifyReducer'

export const staticReducers: Partial<RootReducers> = {
  error: errorReducer,
  system: systemReducer,
  authorization: authorizationReducer,
  announceForm: announceSettingsFormReducer,
  announceDatingGoals: announceDatingGoalsReducer,
  dictionary: dictionaryReducer,
}

export interface RootState {
  router: RouterState
  error: ErrorState
  system: SystemState
  metaData: MetaDataState
  authorization: AuthorizationState
  announceList: AnnounceListState
  announce: AnnounceState
  announceCount: AnnounceCountState
  announceForm: AnnounceSettingsFormState
  announceAnswerForm: AnnounceAnswerFormState
  announceDatingGoals: AnnounceDatingGoalsState
  autoCompleteLocation: AutoCompleteLocationState
  announceCreate: AnnounceCreateState
  announceConfirm: AnnounceConfirmState
  announceDelete: AnnounceDeleteState
  recaptcha: RecaptchaState
  announceShowcase: AnnounceShowcaseState
  announceSubscribeManagement: AnnounceSubscribeManagementState
  seoLinks: SeoLinksState
  subscriptions: SubscriptionsState
  dictionary: DictionaryState
  user: UserState
  verify: VerifyState
}

export interface RootReducers {
  router: Reducer<RouterState>
  error: typeof errorReducer
  system: typeof systemReducer
  metaData: typeof metaDataReducer
  authorization: typeof authorizationReducer
  announceList: typeof announceListReducer
  announce: typeof announceReducer
  announceCount: typeof announceCountReducer
  announceForm: typeof announceSettingsFormReducer
  announceAnswerForm: typeof announceAnswerFormReducer
  announceDatingGoals: typeof announceDatingGoalsReducer
  autoCompleteLocation: typeof autoCompleteLocationReducer
  announceCreate: typeof announceCreateReducer
  announceConfirm: typeof announceConfirmReducer
  announceDelete: typeof announceDeleteReducer
  recaptcha: typeof recaptchaReducer
  announceShowcase: typeof announceShowcaseReducer
  announceSubscribeManagement: typeof announceSubscribeManagementReducer
  seoLinks: typeof seoLinksReducer
  subscriptions: typeof subscriptionsReducer
  dictionary: typeof dictionaryReducer
  user: typeof userReducer
  verify: typeof verifyReducer
}
