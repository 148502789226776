import { Action } from 'redux'
import {
  AsyncAction,
  AsyncThunkAction,
  AsyncThunkAction2,
  AsyncThunkAction3,
} from 'actions/actions.types'
import { Awaited } from 'common/types'
import {
  anonymousAuthorizationApi,
  updateAuthorizationApi,
} from 'api/authorization/anonymousAuthorizationApi'
import { definitions } from 'api/generated/anon_users'
import { fetchUserDataApi } from 'api/authorization/userDataApi'
export const AUTHORIZATION = 'AUTHORIZATION'
export const GET_USER_DATA = 'GET_USER_DATA'

export interface AuthorizationAction
  extends AsyncAction<definitions['AnonUserToken']> {
  type: typeof AUTHORIZATION
}

/**
 * Изначальный метод авторизации на клиенте.
 * Получает либо возвращает токен их хранилища.
 * @param forceNew в любом случае сделать запрос по сети за новым токеном.
 */
export const authorizationAction =
  (forceNew?: boolean): AsyncThunkAction<Action<string>> =>
  (dispatch, getState) => {
    return dispatch({
      type: AUTHORIZATION,
      promise: () =>
        anonymousAuthorizationApi(
          forceNew ? null : getState().authorization.token
        ),
    })
  }

/**
 * Восстанавливает авторизацию, а затем повторяет экшен.
 * @param action повторяемый экшен.
 */
export const tryUpdateAnonymousAuthorizationAction =
  (action: AsyncAction<unknown>): AsyncThunkAction<Promise<unknown>> =>
  async (dispatch, getState) => {
    await dispatch(authorizationAction())
    const { token } = getState().authorization

    if (token) {
      const result = await dispatch(updateSessionAction())

      /** Если токен протух */
      if (result.error?.code === 'anon_user_auth_failed') {
        await dispatch(authorizationAction(true))
        const { token: updatedToken } = getState().authorization

        if (updatedToken) {
          dispatch(updateSessionAction()).catch(console.error)
        } else {
          throw new Error('Can not update authorization token')
        }
      }

      return dispatch(action)
    }

    throw new Error('Authorization token did not received from backend')
  }

export const updateSessionAction =
  (): AsyncThunkAction2<ReturnType<typeof updateAuthorizationApi>> =>
  async (dispatch, getState) => {
    const { token } = getState().authorization

    if (token) {
      /** dispatch используется для того чтобы нормализовать полученный
       *  из API ответ, поэтому сразу строка в типе  */
      return dispatch({
        type: 'UPDATE_SESSION',
        promise: () => updateAuthorizationApi(token),
      })
    }

    throw new Error(
      'Can not update session: authorization token did not received from backend'
    )
  }

export interface GetUserDataAction
  extends AsyncAction<Awaited<ReturnType<typeof fetchUserDataApi>>['result']> {
  type: typeof GET_USER_DATA
}

export const getUserDataAction =
  (): AsyncThunkAction3<ReturnType<typeof fetchUserDataApi>> => (dispatch) => {
    return dispatch({
      type: GET_USER_DATA,
      promise: () => fetchUserDataApi(),
    })
  }
