import { AsyncThunkAction } from 'actions/actions.types'
import { Paths } from 'components/components.paths'
import {
  go as _go,
  goBack as _goBack,
  goForward as _goForward,
  push as _push,
  replace as _replace,
} from 'redux-first-history'
import { isHistoryEmpty } from 'client/functions/isHistoryEmpty'
import { tryScrollTop } from 'functions/tryScrollTop'

export const push =
  (url: string, shouldScrollToTop?: boolean): AsyncThunkAction<void> =>
  (dispatch, getState) => {
    if (getState().system.shouldReloadOnNextPush) {
      return (window.location.href = url)
    }
    if (shouldScrollToTop) {
      tryScrollTop()
    }

    return dispatch(_push(url))
  }

export const replace = (
  url: string,
  uid?: string,
  shouldScrollToTop = false
) => {
  if (shouldScrollToTop) {
    tryScrollTop()
  }

  console.info('Redirect', uid, url)

  return _replace(url)
}

export const go = (count: number, shouldScrollToTop = false) => {
  if (shouldScrollToTop) {
    tryScrollTop()
  }

  return _go(count)
}

export const goBack = (shouldScrollToTop = false) => {
  if (shouldScrollToTop) {
    tryScrollTop()
  }

  return _goBack()
}

export const goForward = (shouldScrollToTop = false) => {
  if (shouldScrollToTop) {
    tryScrollTop()
  }

  return _goForward()
}

export interface LocationState {
  fromWink: boolean
}

export const goBackAction = (): AsyncThunkAction => (dispatch) => {
  if (isHistoryEmpty()) {
    dispatch(push(Paths.index))
  } else {
    dispatch(goBack())
  }
}
