import React from 'react'
import { App } from 'components/App'
import { Authorization } from 'components/system/Authorization'
import { ChangeDictionaryIfNeeded } from 'components/block/ChangeDictionaryIfNeeded/ChangeDictionaryIfNeeded'

import { ErrorRedirect } from 'components/page/notice/error/ErrorRedirect'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { RedirectConfirmAge } from 'components/page/notice/confirmAges/RedirectConfirmAge'
import { Router } from 'react-router-dom'
import { ThemeContext } from 'hooks/useTheme'
import { createLocalesRegExp } from 'constants/locale'
import { fixForReactIntl } from 'functions/locale'
import { history, historyFirstRedux, store } from './configureClientStore'

// history.listen((location, action) => {
//   // location is an object like window.location
//   console.info('listen', action, location.pathname, location.state)
// })

export const InternalStart = () => {
  const {
    system: { language, languages },
  } = store.getState()

  return (
    <Provider store={store}>
      <IntlProvider
        locale={fixForReactIntl(language?.code as string)}
        messages={window.__MESSAGES__}
        defaultLocale={fixForReactIntl(language?.code as string)}
      >
        <ThemeContext.Provider value={window.__THEME__}>
          <Router history={historyFirstRedux}>
            <App
              value={{
                store,
                history,
                localesRegExp: createLocalesRegExp(languages),
              }}
            />
            <RedirectConfirmAge />
            <Authorization />
            <ErrorRedirect />
            <ChangeDictionaryIfNeeded />
          </Router>
        </ThemeContext.Provider>
      </IntlProvider>
    </Provider>
  )
}
