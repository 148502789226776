import React, { FC } from 'react'
import { InternalStart } from './InternalStart'
import { LoadableIntl } from './LoadableIntl'

/**
 * @param locale
 * @constructor
 * Для старых версий < 13 IOS, нет поддержки интернационализации
 * поэтому нужно загрузить. И сделать перерисовку на клиенте.
 */

const ApplicationStart: FC<{ locale: string }> = ({ locale }) => {
  if (!Intl.PluralRules || (!Intl.RelativeTimeFormat && locale)) {
    return <LoadableIntl locale={locale} />
  }

  return <InternalStart />
}

export default ApplicationStart
