/** TODO версии как на мамбе, надо разбираться отдельно */
// @ts-ignore
import { v1 } from 'uuid'

const DEVICE_STORAGE_KEY = 'DEVICE_STORAGE_KEY'

const updateDeviceId = (): string => {
  try {
    const deviceId = v1()
    localStorage.setItem(DEVICE_STORAGE_KEY, deviceId)

    return deviceId
  } catch (error) {
    // Ошибка с квотой на iOS
    // eslint-disable-next-line quotes
    console.error("Can't save to local storage")
  }

  return v1()
}

/**
 * Получает либо создает новый Device Id.
 */
export const resolveDeviceId = (): string => {
  try {
    const deviceId = localStorage.getItem(DEVICE_STORAGE_KEY)

    if (deviceId) {
      return deviceId
    }

    return updateDeviceId()
  } catch (ignored) {
    return v1()
  }
}
