import { AppStore } from 'common/store/store.types'
import { History, createBrowserHistory } from 'history'
import { RootState, staticReducers } from 'reducers/list'
import { configureStore } from 'common/store/configureStore'
import { createReduxHistoryContext } from 'redux-first-history'
import {
  mergeStateWithStateLocalStorage,
  saveState,
} from 'common/store/localStorage'
import { throttle } from 'functions/throttle'

const TIME_TO_SAVE_STORE_IN_LOCAL_STORAGE = 1000

export const createHistory = (): History => {
  const history = window.browserHistory || createBrowserHistory()
  // Фикс обновления истории после хот релоада
  // https://github.com/supasate/connected-react-router/issues/121#issuecomment-530340947
  if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
    window.browserHistory = history
  }

  return history
}

const initialState = window.__INITIAL_STATE__

const staticReducersInitialState = Object.keys(initialState).reduce<RootState>(
  (result, reducerName) => {
    const name = reducerName as keyof typeof staticReducers
    if (staticReducers[name]) {
      return { ...result, [reducerName]: initialState[name] }
    }

    return result
  },
  {} as RootState
)

export const history = createHistory()

const stateInitial: RootState = staticReducersInitialState

const initialStateWithLocalStorage =
  mergeStateWithStateLocalStorage(stateInitial)

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: history,
  })

const createStore = (): AppStore => {
  const store =
    window.browserStore ||
    configureStore(
      initialStateWithLocalStorage,
      true,
      routerMiddleware,
      routerReducer
    )
  if (process.env.NODE_ENV === 'development' && !window.browserStore) {
    window.browserStore = store
  }

  return store as AppStore
}

export const store = createStore()

export const historyFirstRedux = createReduxHistory(store)

store.subscribe(
  throttle(
    () => saveState(store.getState()),
    TIME_TO_SAVE_STORE_IN_LOCAL_STORAGE,
    true
  )
)
