type QueryData = Record<string, string | boolean | number | string[] | null>
/**
 * Фильтрует пустые строки приведением к булеану.
 * Исключение: 0
 * @param map
 */
export const buildQuery = <P extends object = QueryData>(map: P): string =>
  Object.keys(map)
    .filter((key) => {
      const record = map as unknown as QueryData

      return record[key] || record[key] === 0
    })
    .map((key) => buildQueryArray(map, key))
    .join('&')

export const buildFullQuery = <P extends object = QueryData>(
  map: P
): string => {
  return `?${buildQuery<P>(map)}`
}

/**
 * @param map
 * @param key
 * @returns
 * Если вдруг массив сделаем из этого строку.
 */
const buildQueryArray = <P = QueryData>(map: P, key: string) => {
  const _map = map as unknown as QueryData
  const _key = key as unknown as string

  if (Array.isArray(_map[_key])) {
    const _array = _map[_key] as unknown as string[]

    return _array
      .map((value) => `${encodeURIComponent(key)}[]=${value}`)
      .join('&')
  }

  return `${encodeURIComponent(_key)}=${encodeURIComponent(String(_map[_key]))}`
}
