/**
 * Почему не enum
 * Проблема в linaria, не понимает она и падает с ошибкой.
 */
export const ColorsTheme = {
  primaryDefault: '#4343a7',
  primaryHover: '#2f2f93',
  primaryActive: '#1b1b7f',
  primaryDefaultLight: '#8e92b6',
  primaryText: '#39334d',
  white: 'white',
  black: 'black',
  background: '#eff1f4',
  backgroundIcon: '#fed576',
  textAreaBg: '#ecf3ff',
  warning: '#e06065',
  paidAnswer: '#fff7dc',
  transparent: 'transparent',
  breadCrumbs: '#b1b9c3',
  loader: '#4343a7',
  yellowButtonColorText: '#39334D',
  yellowDefault: '#F2C53D',
  buttonBg: '#310749',
  buttonVerify: '#ED52BA',
  verifyTitlePrimaryText: '#4343A7',
  verifyTitleSecondaryText: '#ED52BA',
  buttonVerifyStart: '#803AF3',
  verifyTermsLink: '#2E7CF6',
}
