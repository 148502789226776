import {
  AUTHORIZATION,
  AuthorizationAction,
  GET_USER_DATA,
  GetUserDataAction,
} from 'actions/authorization/authorizationAnonymousAction'
import { LOGOUT, LogoutAction } from 'actions/authorization/logoutAction'
import {
  POST_EMAIL,
  PostEmailAction,
} from 'actions/authorization/postEmailAction'
import {
  REFRESH_TOKEN,
  RefreshTokenAction,
} from 'actions/authorization/refreshTokenAction'
import {
  RESET_EMAIL,
  resetEmailAction,
} from 'actions/authorization/resetEmailAction'
import { Reducer } from 'redux'
import {
  UPDATE_EMAIL,
  updateEmailAction,
} from 'actions/authorization/updateEmailAction'
import {
  UPDATE_OTP,
  updateOtpAction,
} from 'actions/authorization/updateOtpAction'
import {
  VERIFY_EMAIL,
  VerifyEmailAction,
} from 'actions/authorization/verifyEmailAction'
import { defaultPromiseReducer } from 'reducers/system/defaultPromiseReducer'
import { definitions } from 'api/generated/anon_users'

export interface AuthorizationState {
  authorized: boolean
  authorizing: boolean
  token: definitions['AnonUserToken'] | null
  /** Почта для поля ввода */
  email: string
  /** Проверенная почта */
  verifiedEmail: string
  emailAccepted: boolean
  otp: string
  otpAccepted: boolean
  accessToken: string | null
  refreshToken: string | null
  authorizationBlocked: boolean
  verificationEndTime: undefined | null | number
}

const initialAuthorizationState = {
  otp: '',
  emailAccepted: false,
  otpAccepted: false,
  accessToken: null,
  refreshToken: null,
  verificationEndTime: undefined,
}

export const authorizationReducer: Reducer<
  AuthorizationState,
  | AuthorizationAction
  | ReturnType<typeof updateEmailAction>
  | ReturnType<typeof updateOtpAction>
  | ReturnType<typeof resetEmailAction>
  | PostEmailAction
  | VerifyEmailAction
  | RefreshTokenAction
  | LogoutAction
  | GetUserDataAction
> = (
  state = {
    authorized: false,
    authorizing: false,
    token: null,
    /** Для input должна быть пустая строка */
    email: '',
    verifiedEmail: '',
    ...initialAuthorizationState,
    authorizationBlocked: false,
  },
  action
) => {
  switch (action.type) {
    case AUTHORIZATION:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        (result) => {
          if (result) {
            return { token: result }
          }

          return null
        },
        () => ({ token: null })
      )
    case UPDATE_EMAIL:
      return {
        ...state,
        email: action.value,
      }
    case UPDATE_OTP:
      return {
        ...state,
        otp: action.value.replace(/\D/g, ''),
      }
    case POST_EMAIL:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (action.status === 204) {
          return { emailAccepted: true }
        }

        return {
          /**
           * Сбрасывать emailAccepted не нужно, так как значение либо уже false,
           * либо запрос из ввода кода.
           */
          authorizationBlocked: result?.status === 403,
        }
      })
    case VERIFY_EMAIL:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        (response) => {
          if (action.status === 200 && response?.status === 'success') {
            return {
              accessToken: response.access_token,
              refreshToken: response.refresh_token,
              otp: '',
              otpAccepted: true,
              verifiedEmail: state.email,
            }
          }

          return {
            ...initialAuthorizationState,
            /** Email остался тот же, не верный код */
            emailAccepted: true,
            otp: '',
          }
        },
        (response) => {
          if (response.code === 'auth_expired' || action.status === 500) {
            return {
              ...initialAuthorizationState,
            }
          }

          return {
            otp: '',
          }
        }
      )
    case REFRESH_TOKEN:
      return defaultPromiseReducer(state, action, undefined, (response) => {
        if (action.status === 200 && response?.status === 'success') {
          return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
          }
        }

        return {}
      })
    case LOGOUT:
      return defaultPromiseReducer(state, action, undefined, (response) => {
        if (response?.status === 204) {
          return {
            accessToken: null,
            refreshToken: null,
          }
        }

        return {}
      })
    case RESET_EMAIL:
      return {
        ...state,
        email: '',
        emailAccepted: false,
        otp: '',
        otpAccepted: false,
      }
    case GET_USER_DATA:
      return defaultPromiseReducer(state, action, undefined, (response) => {
        return {
          ...state,
          verificationEndTime: response?.verificationEndTime ?? null,
        }
      })

    default:
      return state
  }
}
